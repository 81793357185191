
import { Options } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import isArray from "lodash/isArray";
import { bic, iban } from "@/validators/validators";
import { mixins } from "vue-class-component";
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import StudentEducationMixin from "@/mixins/StudentEducations/StudentEducationMixin";
import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject } from "@awesome-cordova-plugins/in-app-browser";
import { Capacitor } from "@capacitor/core";
import { useIonRouter } from "@ionic/vue";

const LivePayMandateModule = namespace("live-pay-mandate");
const CountryModule = namespace("country");
const LivePayGoCardlessModule = namespace("live-pay-go-cardless");

@Options({
  components: {},
  validations: {
    country: { required },
    firstName: { required, minLength: minLength(2) },
    lastName: { required, minLength: minLength(2) },
    iban: { required, validIban: (value: string) => iban(value) },
    bic: { required, validBic: (value: string) => bic(value) },
    postalCode: { required, minLength: minLength(4) },
    location: { required, minLength: minLength(3) },
    streetAndNumber: { required, minLength: minLength(10) },
    email: { required, email },
  },
})
export default class LivePayRegisterForm extends mixins(StudentEducationMixin) {
  public name = "LivePayRegisterForm";

  public v$ = useVuelidate();
  private ionRouter = useIonRouter();

  @LivePayMandateModule.Action("create")
  public livePayMandateActivateAction: any;

  @LivePayMandateModule.Getter("getSuccess")
  public livePayMandateActivateSuccess: any;

  @LivePayMandateModule.Getter("getIsLoading")
  public livePayMandateLoading: any;

  @CountryModule.Action("findAll")
  public findCountriesAction: any;

  @CountryModule.Getter("getDataList")
  public countries: any;

  @CountryModule.Getter("getIsLoading")
  public countriesLoading: any;

  @LivePayMandateModule.Action("findOne")
  public isLivePayMandateSetUpAction: any;

  @LivePayMandateModule.Getter("getDataItem")
  public isLivePayMandateSetUp: any;

  @LivePayGoCardlessModule.Action("simplePost")
  public livePayGenerateLink!: (options: any) => Promise<void>;

  @LivePayGoCardlessModule.Getter("getDataItem")
  public link: any;

  @LivePayGoCardlessModule.Getter("getSuccess")
  public linkSuccess: any;

  @LivePayGoCardlessModule.Getter("getIsLoading")
  public linkIsLoading: any;

  private country = null;
  private firstName = null;
  private lastName = null;
  private iban = null;
  private bic = null;
  private postalCode = null;
  private location = null;
  private streetAndNumber = null;
  private email = null;

  private browser!: InAppBrowserObject;

  public mounted(): void {
    if (!isArray(this.countries) || this.countries.length === 0) {
      this.loadCountries();
    }
  }

  get loading(): boolean {
    return this.livePayMandateLoading || this.countriesLoading;
  }

  private loadCountries(): void {
    this.findCountriesAction({
      resource: "countries",
      hideSuccessMessage: true,
    });
  }

  private async onGenerateCardlessLink() {
    await this.livePayGenerateLink({
      resource: `live-pay-mandate/generate-go-cardless-link/${this.selectedEducation.id}`,
    });

    if (this.linkSuccess && this.link) {
      let target = "_self";

      if (Capacitor.isNativePlatform()) {
        target = "_blank";
      }

      this.browser = InAppBrowser.create(encodeURI(this.link), target, {
        toolbarposition: "top",
        hidenavigationbuttons: "yes",
        clearcache: "yes",
        clearsessioncache: "yes",
        hideurlbar: "yes",
        zoom: "no",
      });

      this.browser.on("loadstart").subscribe((event: InAppBrowserEvent) => {
        if (event && event.url) {
          if (event.url.includes("/set-up-mandate/success")) {
            this.browser.close();
            this.ionRouter.navigate("/set-up-mandate/success", "forward", "replace");
          }
        }
      });
    }
  }

  private onClickInformationButton() {
    window.open("assets/img/konto.pdf", "_blank");
  }
}
