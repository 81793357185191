
import { Options, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import AccountList from "@/components/Pages/Account/AccountList.vue";
import AccountBlock from "@/components/Pages/Home/Account/AccountBlock.vue";
import { mixins } from "vue-class-component";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin.ts";
import LivePayRegisterForm from "@/components/Pages/Account/LivePayRegisterForm.vue";
import { namespace } from "s-vuex-class";
import StudentDocumentsStoreMixin from "@/mixins/StudentDocumentsStoreMixin.ts";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";
import StudentEducationMixin from "@/mixins/StudentEducations/StudentEducationMixin";

const LivePayMandateModule = namespace("live-pay-mandate");
const DrivingSchoolModule = namespace("driving-school");

@Options({
  components: {
    ForwardHeader,
    LivePayRegisterForm,
    AccountBlock,
    AccountList,
    DefaultLayout,
  },
})
export default class Account extends mixins(StudentEducationsStoreMixin, StudentEducationMixin, StudentDocumentsStoreMixin) {
  public name = "Account";

  @LivePayMandateModule.Getter("getDataItem")
  public isLivePayMandateSetUp: any;

  @LivePayMandateModule.Action("findOne")
  public isLivePayMandateSetUpAction: any;

  @LivePayMandateModule.Getter("getSuccess")
  public livePayMandateActivateSuccess: any;

  @DrivingSchoolModule.Action("findOne")
  public findIsDrivingSchoolEnabled: any;

  @DrivingSchoolModule.Getter("getDataItem")
  public isDrivingSchoolEnabled: any;

  public mounted(): void {
    this.findIsDrivingSchoolEnabled({
      resource: "live-pay/driving_school_enabled/current",
    });
  }

  /**
   * TODO code duplication
   * @param studentEducation
   * @private
   */
  private isLivePaySetUp(studentEducation: any): void {
    if (!studentEducation || !studentEducation.id || studentEducation?.mainLicenseClass === "Alle") {
      return;
    }
    this.isLivePayMandateSetUpAction({
      resource: "live-pay-mandate/by-student-education",
      id: `${studentEducation.id}/is-set-up`,
      hideSuccessMessage: true,
    });
  }

  @Watch("livePayMandateActivateSuccess", { immediate: true, deep: true })
  private onLivePayMandateActivateSuccessChange(livePayMandateActivateSuccess: any): void {
    if (true === livePayMandateActivateSuccess) {
      this.isLivePaySetUp(this.selectedEducation);
      this.loadStudentDocuments();
    }
  }
}
